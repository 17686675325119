import {
  GET_QUIZ_TYPES,
  UPDATE_GET_QUIZ_TYPES,
  GET_QUIZ_FORMATS,
  GET_QUIZ_LEVELS,
  SELECT_QUIZ_LEVEL,
  GET_QUIZ_QUESTIONS,
  CURRENT_QUIZ_QUESTION,
  QUIZ_RESULT,
  SAVE_QUIZ_STATS_AFTER_QUIZ_OVER,
  GET_USER_QUIZ_STATS,
  SET_QUIZ_FORMATS_TO,
  USERS_GIVEN_QUIZ_ANSWER,
  LOGOUT,
  GET_MORE_QUIZ_QUESTIONS,
  SELECTED_QUIZ_TYPE,
  SELECTED_QUIZ_FORMAT,
  RESET_LOCAL_QUIZ_RESULT,
  FINAL_GAME_RESULT_DETAILS,
  RESET_FINAL_GAME_RESULT_DETAILS,
} from "../types";

// get quiz types
const INITIAL_QUIZ_TYPES = {
  count: null,
  next: null,
  previous: null,
  results: null,
};
export const getQuizTypes_reducer = (state = INITIAL_QUIZ_TYPES, action) => {
  switch (action.type) {
    case GET_QUIZ_TYPES: {
      return {
        ...state,
        count: action.payload.count,
        next: action.payload.next,
        previous: action.payload.previous,
        results: action.payload,
      };
    }
    case UPDATE_GET_QUIZ_TYPES: {
      return {
        ...state,
        count: action.payload.count,
        next: action.payload.next,
        previous: action.payload.previous,
        results: action.payload.results,
      };
    }

    case LOGOUT: {
      return INITIAL_QUIZ_TYPES;
    }

    default: {
      return state;
    }
  }
};

export const selectedQuizType_reducer = (state = null, action) => {
  switch (action.type) {
    case SELECTED_QUIZ_TYPE: {
      return action.payload;
    }

    default: {
      return state;
    }
  }
};

// get quiz formats
export const getQuizFormats_reducer = (state = null, action) => {
  switch (action.type) {
    case GET_QUIZ_FORMATS: {
      return action.payload;
    }
    case SET_QUIZ_FORMATS_TO: {
      return action.payload;
    }
    case LOGOUT: {
      return null;
    }

    default: {
      return state;
    }
  }
};

// get quiz formats
export const getQuizLevels_reducer = (state = null, action) => {
  switch (action.type) {
    case GET_QUIZ_LEVELS: {
      return action.payload;
    }

    case LOGOUT: {
      return null;
    }

    default: {
      return state;
    }
  }
};

// selected quiz format
export const selectedQuizFormat_reducer = (state = null, action) => {
  switch (action.type) {
    case SELECTED_QUIZ_FORMAT: {
      return action.payload;
    }

    default: {
      return state;
    }
  }
};

// select quiz level
export const selectQuizLevel_reducer = (state = null, action) => {
  switch (action.type) {
    case SELECT_QUIZ_LEVEL: {
      return action.payload;
    }

    case LOGOUT: {
      return null;
    }

    default: {
      return state;
    }
  }
};

// get quiz questions
export const getQuizQuestions_reducer = (state = null, action) => {
  switch (action.type) {
    case GET_QUIZ_QUESTIONS: {
      return action.payload;
    }

    case GET_MORE_QUIZ_QUESTIONS: {
      const newQuestionList = Array.isArray(action?.payload)
        ? action?.payload
        : [];

      const newState = {
        ...state,
        question_answer_list: Array.isArray(state?.question_answer_list)
          ? [...state?.question_answer_list, ...newQuestionList]
          : state?.question_answer_list,
      };

      return newState;
    }

    case LOGOUT: {
      return null;
    }

    default: {
      return state;
    }
  }
};

// current quiz question
export const currentQuizQuestion_reducer = (state = null, action) => {
  switch (action.type) {
    case CURRENT_QUIZ_QUESTION: {
      return action.payload;
    }

    case LOGOUT: {
      return null;
    }

    default: {
      return state;
    }
  }
};

// quiz results
const INITIAL_QUIZ_RESULT = {
  quiz_type: "",
  quiz_format: "",
  quiz_level: "",
  user: "",
  total_questions: "",
  correct_answers: 0,
  wrong_answers: 0,
  score: 0,
};

export const quizResults_reducer = (state = INITIAL_QUIZ_RESULT, action) => {
  switch (action.type) {
    case QUIZ_RESULT: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case RESET_LOCAL_QUIZ_RESULT: {
      return {
        ...state,
        total_questions: "",
        correct_answers: 0,
        wrong_answers: 0,
        score: 0,
      };
    }

    case LOGOUT: {
      return INITIAL_QUIZ_RESULT;
    }

    default: {
      return state;
    }
  }
};

// save quiz stats after quiz over
export const saveQuizStats_reducer = (state = null, action) => {
  switch (action.type) {
    case SAVE_QUIZ_STATS_AFTER_QUIZ_OVER: {
      return action.payload;
    }

    case LOGOUT: {
      return null;
    }

    default: {
      return state;
    }
  }
};

// get logged in user total points
export const getUserQuizStats_reducer = (state = null, action) => {
  switch (action.type) {
    case GET_USER_QUIZ_STATS: {
      return action.payload;
    }

    case LOGOUT: {
      return null;
    }

    default: {
      return state;
    }
  }
};

// user's given quiz answer
export const usersGivenQuizAnswer_reducer = (state = "", action) => {
  switch (action.type) {
    case USERS_GIVEN_QUIZ_ANSWER: {
      return action.payload;
    }

    case LOGOUT: {
      return "";
    }

    default: {
      return state;
    }
  }
};

// final game result details
export const finalGameResultDetails_reducer = (state = [], action) => {
  switch (action.type) {
    case FINAL_GAME_RESULT_DETAILS: {
      return [...state, action.payload];
    }
    case RESET_FINAL_GAME_RESULT_DETAILS: {
      return [];
    }

    default: {
      return state;
    }
  }
};
