import React, { useEffect } from "react";
import "./style.css"


const PrivacyPolicy = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://app.termly.io/embed-policy.min.js";
    script.async = true;

    document.body.appendChild(script);
  }, []);

  return (
    <div
      name="termly-embed"
      data-id="45ad51e2-ed9b-4849-bbf5-6c7dbd0394f9"
      data-type="iframe"
      className="min-h-screen my-auto mb-2 privacy_policy"
    ></div>
  );
};

export default PrivacyPolicy;


// _09Zszrh9gVarxNB22FnkKA==