import { GUEST_LOGIN, LOGIN, LOGOUT } from "../types";

export const login_reducer = (state = null, action) => {
  switch (action.type) {
    case LOGIN: {
      return action.payload;
    }

    case LOGOUT: {
      return null;
    }

    default: {
      return state;
    }
  }
};

// guest login
export const guestLogin_reducer = (state = null, action) => {
  switch (action.type) {
    case GUEST_LOGIN: {
      return action.payload;
    }

    case LOGOUT: {
      return null;
    }

    default: {
      return state;
    }
  }
};
