// import { useEffect, useState } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import PrivateRoute from "./Routes/PrivateRoute";
// import PublicRoute from "./Routes/PublicRoute";

// pages
// import LoginPage from "./pages/Login";
// import UserProfile from "./pages/userProfile";
// import NameSelectPage from "./pages/name_select";
// import ConfirmPasswordPage from "./pages/confirm_password";
// import ForgotPasswordPage from "./pages/forgot_password";
// import ChooseGame from "./pages/ChooseGame";
// import MathLevel from "./pages/math_level";

// import AboutMathletico from "./pages/about_mathletico";
// import AfterLevelSelectPage from "./pages/after_level_select";
// import TimesUpPage from "./pages/Time_upPage";

// web pages
import { useSelector } from "react-redux";
// import AboutUs from "./pages/About_Us/about";
// import ContactUs from "./pages/Contact_Us/contact";
// import Help from "./pages/Help/help";
// import BlogListing from "./pages/Blog/blog_listing";
// import BlogDetails from "./pages/Blog/blog_details";
// import ChooseFormat from "./pages/choose_format";
// import Leaderboard from "./pages/leaderboard";

// import _bgm from "./assets/sound effects/Freedom_ - Dan Lebowitz.mp3";
import clsx from "clsx";
// import Signup from "./pages/signup";
// import VerifyOTP from "./pages/verify_OTP";
// import SelectAvatar from "./pages/select_avatar";
// import HearAboutUs from "./pages/HearAboutUs";
// import SelectBackground from "./pages/select_background";
// import HomePage from "./pages/HomePage/Index";
// import BackdropLoader from "./components/BackdropLoader";
// import RoadMap from "./pages/RoadMap";
import CommingSoon from "./pages/CommingSoon";
import TermsAndConditions from "./pages/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Support from "./pages/support";

const App = () => {
  const location = useLocation();
  // const navigate = useNavigate();

  const websiteTheme = useSelector((state) => state.websiteTheme);

  // const [pathname, setPathname] = useState("");

  // const reload = window.performance
  //   .getEntriesByType("navigation")
  //   .map((nav) => nav.type)
  //   .includes("reload");

  // playing backgroung music
  // useEffect(() => {
  //   if (reload === true) {
  //     setPathname(location.pathname);
  //   }
  //   setPathname(location.pathname);
  // }, [location.pathname, reload]);

  // useEffect(() => {
  //   const bgm = new Audio(_bgm);

  //   async function plm() {
  //     if (pathname === "/question") {
  //       await bgm.play();
  //     } else {
  //       bgm.pause();
  //     }
  //   }

  //   plm();

  //   return () => bgm.pause();
  // }, [pathname]);

  return (
    <div className={clsx(websiteTheme?.theme === "dark" ? "dark" : "light")}>
      {/* global-loader */}
      {/* <BackdropLoader /> */}
      {/*  */}
      <ToastContainer /> {/* for react toastify */}
      {/*  */}
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<CommingSoon />} />

          {/* <Route
            path="/"
            element={
              <PublicRoute
                component={<HomePage />}
                redirectUrl="/choose-game"
              />
            }
          /> */}

          {/* <Route
            path="/about-us"
            element={
              <PublicRoute component={<AboutUs />} redirectUrl="/choose-game" />
            }
          /> */}

          {/* <Route
            path="/hear-about-us"
            element={
              <PublicRoute
                component={<HearAboutUs />}
                redirectUrl="/choose-game"
              />
            }
          /> */}

          {/* <Route
            path="/contact-us"
            element={
              <PublicRoute
                component={<ContactUs />}
                redirectUrl="/choose-game"
              />
            }
          /> */}

          {/* <Route
            path="/blog-listing"
            element={
              <PublicRoute
                component={<BlogListing />}
                redirectUrl="/choose-game"
              />
            }
          /> */}

          {/* <Route
            path="/blog-details/:slug"
            element={
              <PublicRoute
                component={<BlogDetails />}
                redirectUrl="/choose-game"
              />
            }
          /> */}

          {/* login page */}
          {/* <Route
            path="/login"
            element={
              <PublicRoute
                component={<LoginPage />}
                redirectUrl="/choose-game"
              />
            }
          /> */}

          {/* select your avatar page */}
          {/* <Route
            path="/signup"
            element={
              <PublicRoute component={<Signup />} redirectUrl="/choose-game" />
            }
          /> */}

          {/* verify otp */}
          {/* <Route
            path="/verify-otp"
            element={
              <PublicRoute
                component={<VerifyOTP />}
                redirectUrl="/choose-game"
              />
            }
          /> */}

          {/* select user's avatar */}
          {/* <Route
            path="/select-avatar"
            element={
              <PublicRoute
                component={<SelectAvatar />}
                redirectUrl="/choose-game"
              />
            }
          /> */}

          {/* select user's avatar background */}
          {/* <Route
            path="/select-background"
            element={
              <PublicRoute
                component={<SelectBackground />}
                redirectUrl="/choose-game"
              />
            }
          /> */}

          {/* choose background and create an username */}
          {/* <Route
            path="/choose-nickname"
            element={
              <PublicRoute
                component={<NameSelectPage />}
                redirectUrl="/choose-game"
              />
            }
          /> */}

          {/* choose password and email page */}
          {/* <Route
            path="/choose-password"
            element={
              <PublicRoute
                component={<ConfirmPasswordPage />}
                redirectUrl="/choose-game"
              />
            }
          /> */}

          {/* forgot password page */}
          {/* <Route
            path="/forgot-password"
            element={
              <PublicRoute
                component={<ForgotPasswordPage />}
                redirectUrl="/choose-game"
              />
            }
          /> */}

          {/* where did you hear about mathletico page */}
          {/* <Route
            path="/about-mathletico"
            element={
              <PublicRoute
                component={<AboutMathletico />}
                redirectUrl="/choose-game"
              />
            }
          /> */}

          {/* choose quiz page */}
          {/* <Route
            path="/choose-game"
            element={
              <PrivateRoute component={<ChooseGame />} redirectUrl="/" />
            }
          /> */}

          {/* Roadmap*/}
          {/* <Route
            path="/roadmap"
            element={<PrivateRoute component={<RoadMap />} redirectUrl="/" />}
          /> */}

          {/* user profile page */}
          {/* <Route
            path="/user-profile"
            element={
              <PrivateRoute component={<UserProfile />} redirectUrl="/" />
            }
          /> */}

          {/* choose quiz format page */}
          {/* <Route
            path="/choose-format"
            element={
              <PrivateRoute component={<ChooseFormat />} redirectUrl="/" />
            }
          /> */}

          {/* select level page */}
          {/* <Route
            path="/select-level"
            element={<PrivateRoute component={<MathLevel />} redirectUrl="/" />}
          /> */}

          {/* quiz question page */}
          {/* <Route
            path="/question"
            element={
              <PrivateRoute
                component={<AfterLevelSelectPage />}
                redirectUrl="/"
              />
            }
          /> */}

          {/* scoreboard page */}
          {/* <Route
            path="/scoreboard"
            element={
              <PrivateRoute component={<TimesUpPage />} redirectUrl="/" />
            }
          /> */}

          {/* leaderboard page  */}
          {/* <Route
            path="/leaderboard"
            element={
              <PrivateRoute component={<Leaderboard />} redirectUrl="/" />
            }
          /> */}

          {/* redirecting unnecessary routes to the login page */}
          {/* <Route path="/*" element={<Navigate to="/login" />} /> */}

          {/* terms and conditions  */}
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />

          {/* privacy policy  */}
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/support" element={<Support />} />
          <Route path="/*" element={<Navigate to="/" />} />
        </Routes>
      </AnimatePresence>
    </div>
  );
};

export default App;

// boundary=----WebKitFormBoundarydMIgtiA2YeB1Z0kl
