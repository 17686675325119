import clsx from "clsx";
import React from "react";

const CircularProgress = ({
  size = "2rem",
  color = "#FFFFFF",
  width = 4,
  className,
}) => {
  return (
    <div
      className={clsx(
        "inline-block h-8 w-8 animate-spin rounded-full border-solid align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]",
        className
      )}
      role="status"
      style={{
        width: size,
        height: size,
        borderColor: color,
        borderRightColor: "transparent",
        borderWidth: width,
      }}
    ></div>
  );
};

export default CircularProgress;

//   <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
//     Loading...
//   </span>
