import { useState } from "react";

export const useToggleState = (initialState = false) => {
  const [openDialog, setOpenDialog] = useState(initialState);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  return [openDialog, handleOpenDialog, handleCloseDialog];
};
