import { combineReducers } from "redux";

import {
  virtualKeyboard_reducer,
  changeTheme_reducer,
  quizTypePage_reducer,
  isGameRunning_reducer,
  virtualKeyboardSize_reducer,
  forgotPasswordFlow_reducer,
  backdropLoader_reducer,
  deleteAccountPopup_reducer,
  changePasswordPopup_reducer,
  globalSettingsPopup_reducer,
  localSettingsPopup_reducer,
  reviewScreenPopup_reducer,
  logoutPopup_reducer,
} from "./helper";

import { login_reducer, guestLogin_reducer } from "./login";
import {
  signup_reducer,
  registration_data_reducer,
  forgotPassword_reducer,
  getAvatarList_reducer,
  getBackgroundList_reducer,
} from "./signup";
import { authToken_reducer } from "./authToken";
import {
  getQuizTypes_reducer,
  getQuizFormats_reducer,
  getQuizLevels_reducer,
  selectQuizLevel_reducer,
  getQuizQuestions_reducer,
  currentQuizQuestion_reducer,
  quizResults_reducer,
  saveQuizStats_reducer,
  getUserQuizStats_reducer,
  usersGivenQuizAnswer_reducer,
  selectedQuizType_reducer,
  selectedQuizFormat_reducer,
  finalGameResultDetails_reducer,
} from "./quizes";
import { gameSettings_reducer } from "./gameSettings";
import {
  getGlobalSettings_reducer,
  loaclGameSettings_reducer,
} from "./settings";
import { getLeaderBoard_reducer, userStats_reducer } from "./userStats";
import { theme_reducer } from "./theme";
import {
  getUserProfileDetails_reducer,
  updateLocalUserProfileDetailsFlow_reducer,
} from "./user";

const reducers = combineReducers({
  // helpers
  virtualKeyboard: virtualKeyboard_reducer,
  virtualKeyboardSize: virtualKeyboardSize_reducer,
  pageTheme: changeTheme_reducer, // for page theme change
  quizTypePage: quizTypePage_reducer, // quiz type page
  isGameRunning: isGameRunning_reducer, // is game running
  forgotPasswordFlow: forgotPasswordFlow_reducer, // for getting the flow of forgot password
  isBackdropLoading: backdropLoader_reducer, // for backdrop loader
  deleteAccountPopup: deleteAccountPopup_reducer, // for showing or hiding delete account popup
  changePasswordPopup: changePasswordPopup_reducer, // for showing or hiding delete account popup
  globalSettingsPopup: globalSettingsPopup_reducer, // for showing or hiding delete account popup
  localSettingsPopup: localSettingsPopup_reducer, // for showing or hiding delete account popup
  reviewScreenPopup: reviewScreenPopup_reducer, // for showing or hiding delete account popup

  logoutPopup:logoutPopup_reducer, //for logout popup
   
  avatarList: getAvatarList_reducer,
  backgroundList: getBackgroundList_reducer,

  loggedinUserData: login_reducer,
  guestUserData: guestLogin_reducer,
  registrationData: registration_data_reducer,
  userSignup: signup_reducer,
  authToken: authToken_reducer,
  forgotPassword: forgotPassword_reducer,

  // for quizes
  quizTypes: getQuizTypes_reducer,
  selectedQuizType: selectedQuizType_reducer,
  quizFormats: getQuizFormats_reducer, // for quiz formats
  selectedQuizFormat: selectedQuizFormat_reducer, // selected quiz format
  quizLevels: getQuizLevels_reducer, // for quiz levels
  selectedQuizLevel: selectQuizLevel_reducer, // for selecting quiz level
  quizQuestions: getQuizQuestions_reducer, // for quiz questions
  currentQuizQuestion: currentQuizQuestion_reducer, // for quiz questions
  quizResults: quizResults_reducer, // for quiz results
  savedQuizStats: saveQuizStats_reducer, // save quiz stats after quiz is over
  userQuizStats: getUserQuizStats_reducer, // get logged in user total points
  usersGivenQuizAnswer: usersGivenQuizAnswer_reducer, // user's given quiz answer
  finalGameResult: finalGameResultDetails_reducer, // final game result details

  // game settings
  gameSettings: gameSettings_reducer,

  globalSettings: getGlobalSettings_reducer,
  localGameSettings: loaclGameSettings_reducer,

  // user stats
  userStats: userStats_reducer,
  leaderboardData: getLeaderBoard_reducer,

  // user details
  userProfileDetails: getUserProfileDetails_reducer,
  updateUserProfileDetailsFlow: updateLocalUserProfileDetailsFlow_reducer,

  // website theme
  websiteTheme: theme_reducer,
});

export default reducers;
