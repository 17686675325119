import { SET_AUTH_TOKEN, REMOVE_AUTH_TOKEN, LOGOUT } from "../types";

export const authToken_reducer = (state = null, action) => {
  switch (action.type) {
    case SET_AUTH_TOKEN: {
      return action.payload;
    }
    case REMOVE_AUTH_TOKEN: {
      return null;
    }
    case LOGOUT: {
      return null;
    }
    default: {
      return state;
    }
  }
};
