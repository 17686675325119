import React from "react";

import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import mobileExample from "../../assets/image/mobileExample.png";
import tabletExample from "../../assets/image/tabletExample.png";

import mobileTablet from "../../assets/image/mobileTablet.svg";
import mathleticoLogo from "../../assets/logo/mathleticoLogo.svg";
import { useToggleState } from "../../Hooks/useToggleState";
import { useDispatch } from "react-redux";
import { getNotified_action } from "../../redux/action/user";
import CircularProgress from "../../components/CircularProgress";
import { toast } from "react-toastify";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please provide a valid email!")
    .required("Your email is required!"),
});

const MobileView = () => {
  const dispatch = useDispatch();

  const [isLoading, startLoading, stopLoading] = useToggleState();

  const getNotified = (formData) => {
    console.log({ formData });

    startLoading();

    dispatch(
      getNotified_action(
        { email: formData.email },
        {
          err: (m) => {
            stopLoading();
            toast.warning(m);
          },
          done: (m) => {
            stopLoading();
            toast.success(m);
            resetForm();
          },
        }
      )
    );
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: getNotified,
    validationSchema: validationSchema,
  });

  const { errors, touched, getFieldProps, handleSubmit, resetForm } = formik;

  return (
    <div className="bg-gradient-to-t from-[#9A9AB7] to-[#212133] min-h-screen font-Poppins px-4 flex flex-col items-center">
      <div className="w-full">
        <div className="flex justify-center">
          <img src={mathleticoLogo} alt="" className="mt-3 max-h-[120px]" />
        </div>

        <div className="text-center text-white text-xl mt-6">
          Fasten your seatbelts,
          <br />
          we’re on our way!
        </div>

        <div
          className={clsx(
            "mt-6",
            "h-[1px] bg-gradient-to-r from-[rgba(255,255,255,0.1)] via-[rgba(255,255,255,1)] to-[rgba(255,255,255,0.1)]"
          )}
        ></div>

        <div className="w-full">
          <div className="mt-6 text-2xl text-white text-center uppercase">
            coming soon
          </div>

          <div
            className={clsx(
              "flex justify-center gap-3 mt-4",
              "flex-col sm:flex-row"
            )}
          >
            <div className="relative">
              <FormikProvider value={formik}>
                <Form noValidate>
                  <input
                    type="email"
                    placeholder="Email Address"
                    className={clsx(
                      "px-3 py-2 bg-[#4E4E5D] rounded-[10px] border border-white outline-none sm:w-[100%]",
                      "text-white placeholder:text-white text-lg h-[48px] w-full"
                    )}
                    {...getFieldProps("email")}
                  />
                </Form>
              </FormikProvider>
              {Boolean(touched.email) && Boolean(errors.email) && (
                <span className="text-white text-sm font-medium inline-block mt-2 ml-2">
                  {errors.email}
                </span>
              )}
            </div>

            <div>
              <button
                className={clsx(
                  "px-3 py-2 bg-white text-black rounded-[10px] text-lg whitespace-nowrap h-[48px] w-full"
                )}
                onClick={handleSubmit}
                disabled={isLoading}
              >
                {isLoading ? (
                  <CircularProgress size={20} color="#444" />
                ) : (
                  "Get Notified"
                )}
              </button>
            </div>
          </div>
        </div>

        <div
          className={clsx(
            "mt-8",
            "h-[1px] bg-gradient-to-r from-[rgba(255,255,255,0.1)] via-[rgba(255,255,255,1)] to-[rgba(255,255,255,0.1)]"
          )}
        ></div>
      </div>

      {/* <div className="flex flex-col justify-center items-center pb-5">
        <div className="relative mt-8">
          <img src={tabletExample} alt="" className="h-[40vh]" />

          <div className="absolute top-[30%] -left-[52%] h-[80%]">
            <img src={mobileExample} alt="" className=" h-full" />
          </div>
        </div>
      </div> */}
      <div className="flex flex-col justify-center items-center">
        <div className="relative mt-8">
          <img src={mobileTablet} alt="" />
        </div>
      </div>

      <div className="text-sm text-white pb-3 text-center mt-8">
        <span>powered by</span>&nbsp;
        <span className="font-semibold text-lg">Bronze Nation</span>
      </div>
    </div>
  );
};

export default MobileView;
