import React, { useEffect, useState } from "react";
import clsx from "clsx";
import axios from "axios";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";

// import mobileExample from "../../assets/image/mobileExample.svg";
// import tabletExample from "../../assets/image/tabletExample.svg";
import mobileExample from "../../assets/image/mobileExample.png";
import tabletExample from "../../assets/image/tabletExample.png";

import mathleticoLogo from "../../assets/logo/mathleticoLogo.svg";
import { useToggleState } from "../../Hooks/useToggleState";
import { useDispatch } from "react-redux";
import { getNotified_action } from "../../redux/action/user";
import CircularProgress from "../../components/CircularProgress";
import { toast } from "react-toastify";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please provide a valid email!")
    .required("Your email is required!"),
});

const DesktopView = () => {
  const dispatch = useDispatch();

  const [isLoading, startLoading, stopLoading] = useToggleState();

  const getNotified = (formData) => {
    console.log({ formData });

    startLoading();

    dispatch(
      getNotified_action(
        { email: formData.email },
        {
          err: (m) => {
            stopLoading();
            toast.warning(m);
          },
          done: (m) => {
            stopLoading();
            toast.success(m);
            resetForm();
          },
        }
      )
    );
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: getNotified,
    validationSchema: validationSchema,
  });

  const { errors, touched, getFieldProps, handleSubmit, resetForm } = formik;

  // console.log({ fp: { ...getFieldProps("email") }, ss: errors.touched });

  return (
    <div className="bg-white w-full h-screen relative hidden md:block font-Poppins">
      <div
        className={clsx(
          "absolute px-10 py-10 flex flex-col justify-between h-full",
          "w-[53%]"
        )}
      >
        <div className="text-[#212133] text-3xl font-medium">
          Fasten your seatbelts, we’re on our way!
        </div>

        <div className="flex items-end gap-10">
          <div>
            <img
              src={mobileExample}
              alt=""
              className="md:h-[35vh] lg:h-[50vh] xl:"
            />
          </div>

          <div>
            <img
              src={tabletExample}
              alt=""
              className="md:h-[45vh] lg:h-[60vh]"
            />
          </div>
        </div>
      </div>

      <div
        className="md:w-[52%] lg:w-[55%] h-full ml-auto flex justify-center"
        style={{
          clipPath: "polygon(50% 0, 100% 0, 100% 100%, 0% 100%)",
          backgroundColor: "#212133",
        }}
      >
        <div className="flex flex-col justify-center h-full md:ml-[35%] lg:ml-[32%] items-center">
          <div className=" flex justify-center ">
            <img src={mathleticoLogo} alt="" className="mr-[5%]" />
          </div>

          <div
            className={clsx(
              "text-white uppercase mt-8 whitespace-nowrap",
              "text-[clamp(2.25rem,3.5vw,3rem)] md:tracking-wide lg:tracking-widest"
            )}
          >
            coming soon
          </div>

          <div
            className={clsx(
              "flex items-center gap-4 mt-8",
              "md:flex-col lg:flex-row"
            )}
          >
            <div className="relative">
              <FormikProvider value={formik}>
                <Form noValidate>
                  <input
                    type="text"
                    placeholder="Email Address"
                    className={clsx(
                      "px-3 py-3 bg-[#4E4E5D] rounded-[10px] border border-white outline-none",
                      "text-white placeholder:text-white text-lg md:max-w-[300px] 2xl:max-w-full"
                    )}
                    // onChange={(e) => setEmail(e.target.value)}
                    // name={getFieldProps("email").name}
                    // value={getFieldProps("email").value}
                    // onChange={getFieldProps("email").onChange}
                    {...getFieldProps("email")}
                  />
                </Form>
              </FormikProvider>
              {Boolean(touched.email) && Boolean(errors.email) && (
                <span className="text-white text-sm font-medium absolute inline-block mt-2 ml-2">
                  {errors.email}
                </span>
              )}
            </div>

            <button
              className={clsx(
                "bg-white text-black rounded-[10px] whitespace-nowrap text-lg",
                "py-3 md:px-2 lg:px-3 w-full"
              )}
              onClick={handleSubmit}
              disabled={isLoading}
            >
              {isLoading ? (
                <CircularProgress size={20} color="#444" />
              ) : (
                "Get Notified"
              )}
            </button>
          </div>
        </div>
      </div>

      <div className="text-sm text-white fixed bottom-[1.2rem] right-[2rem]">
        <span>powered by</span>&nbsp;
        <span className="font-semibold text-lg">Bronze Nation </span>
      </div>
    </div>
  );
};

export default DesktopView;
