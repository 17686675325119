import React from "react";

import DesktopView from "./DesktopView";
import MobileView from "./MobileView";

const CommingSoon = () => {
  return (
    <>
      <div className="hidden md:block">
        <DesktopView />
      </div>

      <div className="block md:hidden">
        <MobileView />
      </div>
    </>
  );
};

export default CommingSoon;
