import { WEBSITE_THEME } from "../types";

// for website theme
const INITIAL_THEME = {
  theme: "light",
};

export const theme_reducer = (state = INITIAL_THEME, action) => {
  switch (action.type) {
    case WEBSITE_THEME: {
      return { ...state, ...action.payload };
    }

    default: {
      return state;
    }
  }
};
