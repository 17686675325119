import React, { useEffect } from "react";
import "./style.css"

const TermsAndConditions = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://app.termly.io/embed-policy.min.js";
    script.async = true;

    document.body.appendChild(script);
  }, []);

  return (
    <div
      name="termly-embed"
      data-id="a8fb79ea-8ed2-4217-9bb3-38804f4f3af9"
      data-type="iframe"
      className="min-h-screen my-auto mb-2 w-full"
    ></div>
  );
};

export default TermsAndConditions;
