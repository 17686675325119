import {
  SIGNUP,
  REGISTRATION_DATA,
  DELETE_A_FIELD,
  FORGOT_PASSWORD,
  LOGOUT,
  GET_AVATAR_LIST,
  GET_BACKGROUND_LIST,
} from "../types";

// for registration process
const INITIAL_REGISTRATION_DATA = {
  currentProcess: "select-avatar",
  username: "",
  email: "",
  password: "",
  confirm_password: "",
  avatar_type: "",
  avatar_url: "",
  avatar_id: "",
  avatar: "",
  nickname: "",
  hearAboutMathletico: "",
  background: null,
  background_solid_color: "",
  has_background: false,
  backgroundURL: null,
  hear_about_us: "",
};
export const registration_data_reducer = (
  state = INITIAL_REGISTRATION_DATA,
  action
) => {
  switch (action.type) {
    case REGISTRATION_DATA: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case DELETE_A_FIELD: {
      delete state[action.payload];
      return state;
    }

    case LOGOUT: {
      return INITIAL_REGISTRATION_DATA;
    }

    default: {
      return state;
    }
  }
};

export const signup_reducer = (state = null, action) => {
  switch (action.type) {
    case SIGNUP: {
      return action.payload;
    }

    case LOGOUT: {
      return null;
    }

    default: {
      return state;
    }
  }
};

// forgot password reducer
export const forgotPassword_reducer = (state = null, action) => {
  switch (action.type) {
    case FORGOT_PASSWORD: {
      return action.payload;
    }

    case LOGOUT: {
      return null;
    }

    default: {
      return state;
    }
  }
};

// get avatar list
export const getAvatarList_reducer = (state = null, action) => {
  switch (action.type) {
    case GET_AVATAR_LIST: {
      return action.payload;
    }

    default: {
      return state;
    }
  }
};

// get avatar list
export const getBackgroundList_reducer = (state = null, action) => {
  switch (action.type) {
    case GET_BACKGROUND_LIST: {
      return action.payload;
    }

    default: {
      return state;
    }
  }
};
