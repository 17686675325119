// for helpers
export const OPEN_VIRTUAL_KEYBOARD = "OPEN_VIRTUAL_KEYBOARD";
export const CLOSE_VIRTUAL_KEYBOARD = "CLOSE_VIRTUAL_KEYBOARD";
export const VIRTUAL_KEYBOARD_SIZE = "VIRTUAL_KEYBOARD_SIZE";
export const QUIZ_TYPES_PAGE = "QUIZ_TYPES_PAGE";
export const IS_GAME_RUNNING = "IS_GAME_RUNNING";
export const FORGOT_PASSWORD_FLOW = "FORGOT_PASSWORD_FLOW";
export const RESET_FORGOT_PASSWORD_FLOW = "RESET_FORGOT_PASSWORD_FLOW";
export const BACKDROP_LOADER = "BACKDROP_LOADER";
export const DELETE_ACCOUNT_POPUP = "DELETE_ACCOUNT_POPUP";
export const CHANGE_PASSWORD_POPUP = "CHANGE_PASSWORD_POPUP";
export const GLOBAL_SETTINGS_POPUP = "GLOBAL_SETTINGS_POPUP";
export const LOCAL_SETTINGS_POPUP = "LOCAL_SETTINGS_POPUP";
export const REVIEW_SCREEN_POPUP = "REVIEW_SCREEN_POPUP";

// auth
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const GUEST_LOGIN = "GUEST_LOGIN";
export const SIGNUP = "SIGNUP";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const REQUEST_OTP = "REQUEST_OTP";
export const VERIFY_OTP = "VERIFY_OTP";

// Auth Token
export const SET_AUTH_TOKEN = "SET_AUTH_TOKEN";
export const REMOVE_AUTH_TOKEN = "REMOVE_AUTH_TOKEN";

// for registration process
export const REGISTRATION_DATA = "REGISTRATION_DATA";
export const GET_AVATAR_LIST = "GET_AVATAR_LIST";
export const GET_BACKGROUND_LIST = "GET_BACKGROUND_LIST";
export const DELETE_A_FIELD = "DELETE_A_FIELD";

// user profile data
export const GET_USER_PROFILE_DETAILS = "GET_USER_PROFILE_DETAILS";
export const UPDATE_USER_PROFILE_DETAILS = "UPDATE_USER_PROFILE_DETAILS";
export const UPDATE_USER_PROFILE_DETAILS_LOCAL_FLOW =
  "UPDATE_USER_PROFILE_DETAILS_LOCAL_FLOW";
export const RESET_UPDATE_USER_PROFILE_DETAILS_LOCAL_FLOW =
  "RESET_UPDATE_USER_PROFILE_DETAILS_LOCAL_FLOW";

// for quizes
export const GET_QUIZ_TYPES = "GET_QUIZ_TYPES";
export const SELECTED_QUIZ_TYPE = "SELECTED_QUIZ_TYPE";
export const UPDATE_GET_QUIZ_TYPES = "UPDATE_GET_QUIZ_TYPES";

export const GET_QUIZ_FORMATS = "GET_QUIZ_FORMATS";
export const SET_QUIZ_FORMATS_TO = "SET_QUIZ_FORMATS_TO";
export const SELECTED_QUIZ_FORMAT = "SELECTED_QUIZ_FORMAT";

export const GET_QUIZ_LEVELS = "GET_QUIZ_LEVELS";
export const SELECT_QUIZ_LEVEL = "SELECT_QUIZ_LEVEL";
export const GET_QUIZ_QUESTIONS = "GET_QUIZ_QUESTIONS";
export const GET_MORE_QUIZ_QUESTIONS = "GET_MORE_QUIZ_QUESTIONS";

export const CURRENT_QUIZ_QUESTION = "CURRENT_QUIZ_QUESTION";
export const QUIZ_RESULT = "QUIZ_RESULT";
export const RESET_LOCAL_QUIZ_RESULT = "RESET_LOCAL_QUIZ_RESULT";
export const USERS_GIVEN_QUIZ_ANSWER = "USERS_GIVEN_QUIZ_ANSWER";
export const SAVE_QUIZ_STATS_AFTER_QUIZ_OVER =
  "SAVE_QUIZ_STATS_AFTER_QUIZ_OVER";
// full game result
export const FINAL_GAME_RESULT_DETAILS = "FINAL_GAME_RESULT_DETAILS";
export const RESET_FINAL_GAME_RESULT_DETAILS =
  "RESET_FINAL_GAME_RESULT_DETAILS";

export const GET_USER_QUIZ_STATS = "GET_USER_QUIZ_STATS";

// color changing functionality
export const PAGE_THEME_COLORS = "PAGE_THEME_COLORS";

// About game
export const GAME_SETTINGS = "GAME_SETTINGS";

// settings
export const GET_GLOBAL_SETTINGS = "GET_GLOBAL_SETTINGS";
export const UPDATE_GLOBAL_SETTINGS = "UPDATE_GLOBAL_SETTINGS";

export const UPDATE_LOCAL_SETTINGS = "UPDATE_LOCAL_SETTINGS";
export const RESET_LOCAL_SETTINGS = "RESET_LOCAL_SETTINGS";

// users
export const GET_USER_STATS = "GET_USER_STATS";
export const UPDATE_USER_STATS = "UPDATE_USER_STATS";
export const GET_LEADER_BOARD = "GET_LEADER_BOARD";
export const SUBMIT_FEEDBACK = "SUBMIT_FEEDBACK";
export const DELETE_ACCOUNT = "DELETE_ACCOUNT";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const LOGOUT_POPUP="LOGOUT_POPUP";
export const REACTIVE_ACCOUNT="REACTIVE_ACCOUNT";

// for support page
export const CONTACT_US="CONTACT_US";


// website theme/mode (e.g, 'light' or 'dark')
export const WEBSITE_THEME = "WEBSITE_THEME";


// get notified
export const GET_NOTIFIED = "GET_NOTIFIED";
