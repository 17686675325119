import { numpadColors } from "../../constants/numpadColors";
import {
  GET_GLOBAL_SETTINGS,
  LOGOUT,
  RESET_LOCAL_SETTINGS,
  UPDATE_GLOBAL_SETTINGS,
  UPDATE_LOCAL_SETTINGS,
} from "../types";

// global settings
export const getGlobalSettings_reducer = (state = null, action) => {
  switch (action.type) {
    case GET_GLOBAL_SETTINGS: {
      return action.payload;
    }
    case UPDATE_GLOBAL_SETTINGS: {
      return action.payload;
    }
    case LOGOUT: {
      return null;
    }

    default: {
      return state;
    }
  }
};

// local game settings

const INITIAL_LOCAL_GAME_SETTINGS = {
  enable_practice_clock: true,
  invert_numpad: false,
  numpad_color: null,
};

export const loaclGameSettings_reducer = (
  state = INITIAL_LOCAL_GAME_SETTINGS,
  action
) => {
  switch (action.type) {
    case UPDATE_LOCAL_SETTINGS: {
      return { ...state, ...action.payload };
    }

    case RESET_LOCAL_SETTINGS: {
      return INITIAL_LOCAL_GAME_SETTINGS;
    }

    default: {
      return state;
    }
  }
};
