import axios from "axios";

// export const BASE_URL = "http://13.40.14.168";
export const BASE_URL = "https://api.mathletico.com";

export default axios.create({
  baseURL: BASE_URL,
  timeout: 10000,
  timeoutErrorMessage: "Timeout exceeded",
});
// export default axios.create({ baseURL: "https://webidea.pythonanywhere.com" });
