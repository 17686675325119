import { GET_LEADER_BOARD, GET_USER_STATS, UPDATE_USER_STATS } from "../types";

// user stats
export const userStats_reducer = (state = null, action) => {
  switch (action.type) {
    case GET_USER_STATS: {
      return action.payload;
    }
    case UPDATE_USER_STATS: {
      return action.payload;
    }

    default: {
      return state;
    }
  }
};

// leader board data
export const getLeaderBoard_reducer = (state = null, action) => {
  switch (action.type) {
    case GET_LEADER_BOARD: {
      return action.payload;
    }

    default: {
      return state;
    }
  }
};
