import React, { useRef, useState } from "react";
import clsx from "clsx";
import { useFormik, FormikProvider, Form } from "formik";
import * as Yup from "yup";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch } from "react-redux";
import emailjs from "@emailjs/browser";

import { useToggleState } from "../../Hooks/useToggleState";
import {
  contactUs_action,
  submitFeedback_action,
} from "../../redux/action/user";
import { toast } from "react-toastify";
import CircularProgress from "../../components/CircularProgress";

const validationSchema = Yup.object().shape({
  firstname: Yup.string().required("First name is required"),
  lastname: Yup.string(),
  from_email: Yup.string()
    .email("Please enter a valid email address!")
    .required("Please enter a valid email address!"),
  message: Yup.string().required(),
});

const Support = () => {
  const dispatch = useDispatch();

  const captchaRef = useRef(null);
  const formRef = useRef(null);

  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [recaptchaError, setRecaptchaError] = useState(null);

  console.log({ formRef });

  const [isLoading, startLoading, stopLoading] = useToggleState();

  const handleCaptchaChange = (value) => {
    setRecaptchaValue(value);
    console.log({ value });
  };

  const sendEmail = (formData) => {
    if (!recaptchaValue) {
      setRecaptchaError("Please verify your identity!");
      return;
    }
    setRecaptchaError(null);
    startLoading();

    dispatch(
      contactUs_action(
        {
          first_name: formData.firstname,
          last_name: formData.lastname,
          email: formData.from_email,
          message: formData.message,
        },
        {
          err: (m) => {
            stopLoading();
            captchaRef.current.reset();
            setRecaptchaValue(null);
          },
          done: (m) => {
            toast.success("Your message successfully submitted.");
            stopLoading();
            captchaRef.current.reset();
            resetForm();
            setRecaptchaValue(null);
          },
        }
      )
    );
    // emailjs
    //   .send(
    //     // "YOUR_SERVICE_ID",
    //     process.env.REACT_APP_EMAILJS_SERVICE_ID,
    //     // "YOUR_TEMPLATE_ID",
    //     process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
    //     {
    //       firstname: formData.firstname,
    //       lastname: formData.lastname,
    //       from_email: formData.from_email,
    //       message: formData.message,
    //       "g-recaptcha-response": recaptchaValue,
    //     },
    //     // "YOUR_PUBLIC_KEY"
    //     process.env.REACT_APP_EMAILJS_PUBLIC_KEY
    //   )
    //   .then(
    //     (result) => {
    //       toast.success("Your message successfully submitted.");
    //       stopLoading();
    //       captchaRef.current.reset();
    //       resetForm();
    //     },
    //     (error) => {
    //       stopLoading();
    //       captchaRef.current.reset();
    //     }
    //   );
  };

  console.log("process.env.REACT_APP_SITE_KEY", process.env.REACT_APP_SITE_KEY);

  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      from_email: "",
      message: "",
    },
    validationSchema: validationSchema,
    onSubmit: sendEmail,
  });

  const { errors, touched, getFieldProps, handleSubmit, resetForm } = formik;

  return (
    <div className="p-4 sm:p-10 font-Poppins min-h-screen flex justify-between w-full">
      <div className="hidden md:flex flex-col justify-center gap-7 w-full">
        <h1 className="text-[clamp(2.5rem,5vw,3.75rem)] leading-4 text-[#2D2D2D]">
          Talk to us!
        </h1>
        <p className="text-3xl text-[clamp(1rem,3vw,1.875rem)] leading-[3rem] text-[#9D9D9D]">
          Need to get in touch with us? Email us or
          <br />
          fill in the form
        </p>
      </div>

      <div className="max-w-[400px] flex flex-col justify-center mx-auto">
        <div className="bg-[#2D2D2D] text-white !py-8 px-6 rounded-[20px] w-full">
          <h3 className="font-semibold text-xl">Email us at:</h3>
          <p className="text-lg">support@mathletico.com</p>
        </div>

        <FormikProvider value={formik}>
          <Form noValidate ref={formRef} onSubmit={handleSubmit}>
            <div className="mt-8 border w-full space-y-5 py-5 px-8 rounded-[20px] shadow-lg">
              <div className="flex justify-between flex-col sm:flex-row w-full gap-5 sm:gap-8">
                <SupportInput
                  label="First Name"
                  id="firstname"
                  {...getFieldProps("firstname")}
                  err={Boolean(errors.firstname) && Boolean(touched.firstname)}
                  errMsg={errors.firstname}
                />
                <SupportInput
                  label="Last Name"
                  id="lasttname"
                  {...getFieldProps("lastname")}
                  err={Boolean(errors.lastname) && Boolean(touched.lastname)}
                  errMsg={errors.lastname}
                />
              </div>

              <div>
                <SupportInput
                  label="Email"
                  id="email"
                  type="email"
                  {...getFieldProps("from_email")}
                  err={
                    Boolean(errors.from_email) && Boolean(touched.from_email)
                  }
                  errMsg={errors.from_email}
                />
              </div>

              <div>
                <SupportInput
                  textarea={true}
                  label="Your message"
                  id="yourmessage"
                  {...getFieldProps("message")}
                  err={Boolean(errors.message) && Boolean(touched.message)}
                  errMsg={errors.message}
                />
              </div>

              <div className="w-full">
                <ReCAPTCHA
                  size="normal"
                  style={{ margin: "auto",}}
                  sitekey={process.env.REACT_APP_SITE_KEY}
                  onChange={handleCaptchaChange}
                  ref={captchaRef}
                />
                {!recaptchaValue && recaptchaError && (
                  <span className="text-red-600 pl-2 text-sm">
                    {recaptchaError}
                  </span>
                )}
              </div>

              <div className="text-center mt-2">
                <button
                  className="bg-[#2B6DCE] text-white rounded-[99999px] px-4 py-2 text-center outline-none min-w-[150px] text-xl font-medium"
                  type="submit"
                  // onClick={handleSubmit}
                >
                  {isLoading ? (
                    <CircularProgress size={20} color="#FFFFFF" />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </div>
          </Form>
        </FormikProvider>
      </div>
    </div>
  );
};

export default Support;

function SupportInput({
  name,
  id,
  label = "First Name",
  value,
  onChange,
  className,
  textarea = false,
  err,
  errMsg,
  type = "text",
}) {
  if (textarea === true) {
    return (
      <div className="w-full">
        <label htmlFor={id} className="m-0 p-0 inline-block !mb-2">
          {label}
        </label>
        <textarea
          type="text"
          name={name}
          id={id}
          value={value}
          onChange={onChange}
          rows={3}
          className={clsx(
            "border border-[#9D9D9D] px-3 py-2 focus:border-[#2D2D2D] outline-none rounded-[20px] w-full",
            className
          )}
        />
        {err && <span className="text-red-600 text-sm pl-3">{errMsg}</span>}
      </div>
    );
  }

  return (
    <div className="w-full">
      <label htmlFor={id} className="m-0 p-0 inline-block !mb-1">
        {label} <span className="text-[#FF0000]">*</span>
      </label>
      <input
        type={type}
        name={name}
        id={id}
        value={value}
        onChange={onChange}
        className={clsx(
          "border border-[#9D9D9D] px-3 py-2 focus:border-[#2D2D2D] outline-none rounded-[10px] w-full",
          className
        )}
      />
      {err && <span className="text-red-600 text-xs pl-2">{errMsg}</span>}
    </div>
  );
}
