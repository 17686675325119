import {
  GET_USER_PROFILE_DETAILS,
  RESET_UPDATE_USER_PROFILE_DETAILS_LOCAL_FLOW,
  UPDATE_USER_PROFILE_DETAILS_LOCAL_FLOW,
} from "../types";

// get user profile details
export const getUserProfileDetails_reducer = (state = null, action) => {
  switch (action.type) {
    case GET_USER_PROFILE_DETAILS: {
      return action.payload;
    }

    default: {
      return state;
    }
  }
};

// // update user profile details
// export const updateUserProfileDetails =
//   (formData, { err, done }) =>
//   async (dispatch) => {
//     try {
//       const {} = axios.pu
//     } catch (error) {

//     }
//   };

// update loacal user profile details flow
const INITIAL_UPDATE_PROFILE_DETAILS_STATE = {
  selectedTab: "animal",
  title: "Select avatar",
  avatar: null,
  has_background: false,
  background: null,
  background_solid_color: "",
};

export const updateLocalUserProfileDetailsFlow_reducer = (
  state = INITIAL_UPDATE_PROFILE_DETAILS_STATE,
  action
) => {
  switch (action.type) {
    case UPDATE_USER_PROFILE_DETAILS_LOCAL_FLOW: {
      return { ...state, ...action.payload };
    }
    case RESET_UPDATE_USER_PROFILE_DETAILS_LOCAL_FLOW: {
      return INITIAL_UPDATE_PROFILE_DETAILS_STATE;
    }

    default: {
      return state;
    }
  }
};
