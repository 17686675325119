import { GAME_SETTINGS } from "../types";

const INITIAL_GAME_SETTINGS = {
  hidePractiseClock: false,
  hideScoring: false,
  enableSoundEffects: false,
  enableMusic: false,
  invertNumpad: false,
};
export const gameSettings_reducer = (state = INITIAL_GAME_SETTINGS, action) => {
  switch (action.type) {
    case GAME_SETTINGS: {
      return { ...state, ...action.payload };
    }

    default: {
      return state;
    }
  }
};
