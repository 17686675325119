import { createStore, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import reducers from "../reducers";

const persistConfig = {
  key: "root",
  storage,
  blacklist: [
    "quizTypePage",
    "virtualKeyboardSize",
    "localGameSettings",
    "isBackdropLoading",
    "updateUserProfileDetailsFlow",
    "forgotPasswordFlow",
    "deleteAccountPopup",
    "changePasswordPopup",
    "globalSettingsPopup",
    "localSettingsPopup",
    "reviewScreenPopup",
    "logoutPopup",
  ],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store =
  process.env.NODE_ENV === "development"
    ? createStore(
        persistedReducer,
        {},
        compose(
          applyMiddleware(thunk),
          (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
            window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()) ||
            compose
        )
      )
    : createStore(persistedReducer, {}, compose(applyMiddleware(thunk)));

export let persistor = persistStore(store);
export default store;
