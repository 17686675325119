import {
  OPEN_VIRTUAL_KEYBOARD,
  CLOSE_VIRTUAL_KEYBOARD,
  PAGE_THEME_COLORS,
  QUIZ_TYPES_PAGE,
  IS_GAME_RUNNING,
  VIRTUAL_KEYBOARD_SIZE,
  FORGOT_PASSWORD_FLOW,
  BACKDROP_LOADER,
  RESET_FORGOT_PASSWORD_FLOW,
  DELETE_ACCOUNT_POPUP,
  CHANGE_PASSWORD_POPUP,
  GLOBAL_SETTINGS_POPUP,
  LOCAL_SETTINGS_POPUP,
  REVIEW_SCREEN_POPUP,
  LOGOUT_POPUP,
} from "../types";

// open virtual keyboard
export const virtualKeyboard_reducer = (state = true, action) => {
  switch (action.type) {
    case OPEN_VIRTUAL_KEYBOARD: {
      return true;
    }
    case CLOSE_VIRTUAL_KEYBOARD: {
      return false;
    }

    default: {
      return state;
    }
  }
};

// change page theme color
const INITIAL_THEME_COLORS = {
  numpadBg: "#92D3F5",
  numpadColor: "#000000",
};

export const changeTheme_reducer = (state = INITIAL_THEME_COLORS, action) => {
  switch (action.type) {
    case PAGE_THEME_COLORS: {
      return action.payload;
    }

    default: {
      return state;
    }
  }
};

// quiz type page
export const quizTypePage_reducer = (state = 0, action) => {
  switch (action.type) {
    case QUIZ_TYPES_PAGE: {
      return action.payload;
    }

    default: {
      return state;
    }
  }
};

// is game running
export const isGameRunning_reducer = (state = false, action) => {
  switch (action.type) {
    case IS_GAME_RUNNING: {
      return action.payload;
    }

    default: {
      return state;
    }
  }
};

// virtual keyboard size
const INITIAL_VK_SIZE = {
  btnWidth: "",
  // btnHeight: "58px",
  btnHeight: "60px",
};
export const virtualKeyboardSize_reducer = (
  state = INITIAL_VK_SIZE,
  action
) => {
  switch (action.type) {
    case VIRTUAL_KEYBOARD_SIZE: {
      if (action.payload.default === true) {
        return INITIAL_VK_SIZE;
      }
      return { ...state, ...action.payload };
    }

    default: {
      return state;
    }
  }
};

// forgor password flow
export const forgotPasswordFlow_reducer = (state = null, action) => {
  switch (action.type) {
    case FORGOT_PASSWORD_FLOW: {
      return action.payload;
    }
    case RESET_FORGOT_PASSWORD_FLOW: {
      return null;
    }

    default: {
      return state;
    }
  }
};

//  for backdrop loader
export const backdropLoader_reducer = (state = false, action) => {
  switch (action.type) {
    case BACKDROP_LOADER: {
      return action.payload;
    }

    default: {
      return state;
    }
  }
};

// for delete account popup
export const deleteAccountPopup_reducer = (state = false, action) => {
  switch (action.type) {
    case DELETE_ACCOUNT_POPUP: {
      return action.payload;
    }

    default: {
      return state;
    }
  }
};

// change password popup
export const changePasswordPopup_reducer = (state = false, action) => {
  switch (action.type) {
    case CHANGE_PASSWORD_POPUP: {
      return action.payload;
    }

    default: {
      return state;
    }
  }
};

// global settings popup
export const globalSettingsPopup_reducer = (state = false, action) => {
  switch (action.type) {
    case GLOBAL_SETTINGS_POPUP: {
      return action.payload;
    }

    default: {
      return state;
    }
  }
};

// local settings popup
export const localSettingsPopup_reducer = (state = false, action) => {
  switch (action.type) {
    case LOCAL_SETTINGS_POPUP: {
      return action.payload;
    }

    default: {
      return state;
    }
  }
};

// review screen popup
export const reviewScreenPopup_reducer = (state = false, action) => {
  switch (action.type) {
    case REVIEW_SCREEN_POPUP: {
      return action.payload;
    }

    default: {
      return state;
    }
  }
};

// Logout popup
export const logoutPopup_reducer = (state = false, action) => {
  switch (action.type) {
    case LOGOUT_POPUP: {
      return action.payload;
    }

    default: {
      return state;
    }
  }
};
