import axios from "../../axios";
import {
  CHANGE_PASSWORD,
  DELETE_ACCOUNT,
  GET_USER_PROFILE_DETAILS,
  REACTIVE_ACCOUNT,
  RESET_UPDATE_USER_PROFILE_DETAILS_LOCAL_FLOW,
  SUBMIT_FEEDBACK,
  UPDATE_USER_PROFILE_DETAILS,
  UPDATE_USER_PROFILE_DETAILS_LOCAL_FLOW,
} from "../types";

// get user profile data
export const getUserProfileDetails_action =
  (authToken, username, { err, done }) =>
    async (dispatch) => {
      try {
        const { data } = await axios.get(`/accounts/detail/${username}`, {
          headers: {
            Authorization: `JWT ${authToken}`,
          },
        });

        dispatch({ type: GET_USER_PROFILE_DETAILS, payload: data });

        done && done();
      } catch (error) {
        err && err();
      }
    };

// update user profile details
export const updateUserProfileDetails_action =
  (authToken, username, formData, { err, done }) =>
    async (dispatch) => {
      try {
        const { data } = await axios.put(
          `/accounts/update/${username}`,
          formData,
          { headers: { Authorization: `JWT ${authToken}` } }
        );

        dispatch({ type: UPDATE_USER_PROFILE_DETAILS });

        done && done();
      } catch (error) {
        err && err();
      }
    };

// update local user profile details flow
export const updateUserProfileDetailsFlow_action = (
  profileData = {
    selectedTab: "animal",
    title: "Select avatar",
    avatar: null,
    has_background: false,
    background: null,
    background_solid_color: "",
  }
) => {
  return { type: UPDATE_USER_PROFILE_DETAILS_LOCAL_FLOW, payload: profileData };
};

// reset update local user profile details flow
export const resetUpdateUserProfileDetailsFlow_action = () => {
  return { type: RESET_UPDATE_USER_PROFILE_DETAILS_LOCAL_FLOW };
};

// for submit feedback
export const submitFeedback_action =
  (authToken, formData, { err, done }) =>
    async (dispatch) => {
      try {
        const { data, status } = await axios.post("/quiz/feedback/", formData, {
          headers: { Authorization: `JWT ${authToken}` },
        });

        dispatch({ type: SUBMIT_FEEDBACK, payload: data });

        done && done(data?.detail);
      } catch (error) {
        err && err();
      }
    };

// delete account
export const deleteAccount_action =
  (authToken, formData = { password: "" }, { err, done }) =>
    async (dispatch) => {
      try {
        const { data } = await axios.post("/accounts/delete-account/", formData, {
          headers: { Authorization: `JWT ${authToken}` },
        });

        dispatch({ type: DELETE_ACCOUNT });

        done && done(data?.detail);
      } catch (error) {
        err && err(error?.response?.data?.detail);
      }
    };

// change password
export const changePassword_action =
  (authToken, formData, { err, done }) =>
    async (dispatch) => {
      try {
        const { data } = await axios.post(
          "/accounts/change-password/",
          formData,
          {
            headers: { Authorization: `JWT ${authToken}` },
          }
        );

        dispatch({ type: CHANGE_PASSWORD });

        done && done(data?.detail);
      } catch (error) {
        err && err(error?.response?.data?.detail);
      }
    };

// reactive deleted account
export const reactiveUserAccount_action =
  (formData = { username: "" }, { err, done }) =>
    async (dispatch) => {
      try {
        const { data } = await axios.post(
          "/accounts/reactivate-account/",
          formData
        );

        dispatch({ type: REACTIVE_ACCOUNT, payload: "" });

        done && done(data?.detail);
      } catch (error) {
        err && err(error?.response?.data?.detail);
      }
    };

// for get notified
export const getNotified_action =
  (formData = { email: "" }, { err, done }) =>
    async (dispatch) => {
      try {
        const { data } = await axios.post("/accounts/elastic-email/", formData);

        done && done("Thanks for your submission, you will be notified.");
      } catch (error) {
        console.log({ error: error });
        err && err(error?.response?.data || error?.message);
      }
    };


// for support and contact us
export const contactUs_action = (formData = {
  first_name: "",
  last_name: "",
  email: "",
  message: ""
}, { err, done }) => async dispatch => {
  try {
    const { data } = await axios.post("/quiz/contact-us/", formData)
    done && done()
  } catch (error) {
    err && err()
  }
}